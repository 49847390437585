

* {
  text-rendering: optimizeLegibility;
  // box-shadow: none !important;
  font-family: 'Poppins', sans-serif !important;
}
#root {
}
body {
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
.analyticsIcon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  object-fit: contain;
  margin-bottom: 10px;
}

.line {
  vertical-align: middle;
  object-fit: contain;
  text-align: center;
  width: 100%;
  height: 20px;
  margin: 30px auto;
}
.field {
  margin: 50px auto;
}

.icon {
  width: 28px;
  height: 28px;
  vertical-align: middle;
  object-fit: contain;
}
.smIcon {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.lgIcon {
  width: 80px;
  height: 80px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.block {
  margin-top: 60px;
}
.header {
  text-align: center;
  font-size: 40px;
  padding-top: 25px;
  font-weight: 700;
}
.card {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  // background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  overflow: auto;
  margin: 10px 0px;
  padding: 20px;
}

.valueCard {
  background: linear-gradient(transparent, #f53838);
  box-shadow: 5px 13px 12px 4px rgba(225, 0, 0, 0.4);
  border: 1.5px solid #f53838;
  color: white;
  text-align: center;
  font-size: 22px;
  overflow: hidden;
  border-radius: 15px;
  margin: 10px;
  padding: 30px;
  min-height: 350px;
}

.projectCard {
  color: white;
  overflow: hidden;
  text-align: left;
  height: 200px;
  border-radius: 15px;
  margin: 10px;
  padding: 30px;
}
.alertCard {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
}
.cardHeader {
  color: #636b7d;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0;
  font-weight: 700;
  margin-bottom: 15px;
}
.cardSubHeader {
  color: #636b7d;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
}

.cardContent {
  color: #636b7d;
  font-size: 14px;
  line-height: 1.3;
  margin-top: 15px;
  font-weight: 500;
}
.cardColorContent {
  color: white;
  font-size: 18px;
  line-height: 1.3;
}

.cardValue {
  color: #404040;
  font-size: 18px;
  font-weight: 700;
}
.cardLgValue {
  color: #0b308e;
  font-size: 21px;
  font-weight: 600;
}
.cardGreenValue {
  color: #519d5d;
  font-size: 18px;
  font-weight: 600;
}
.cardRedValue {
  color: #ee482c;
  font-size: 18px;
  font-weight: 600;
}
.tab {
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}
.lightTab {
  background-color: rgba(225, 225, 225, 0.3);
  overflow: hidden;
  border-radius: 10px;
  margin: "0 10px";
  vertical-align: bottom;
  padding: 20px 20px;
}
.title {
  font-size: 43px;
  color: #404040;
  font-weight: 600;
  line-height: 1.5;
}
.subTitle {
  color: #aeaeae;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.coloredTitle {
  color: white;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}
.coloredText {
  color: white !important;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboardHeader {
  font-size: 30px;
  vertical-align: middle;
  font-weight: 700;
}
.listHeader {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.listContent {
  text-align: right;
  font-size: 22px;
  font-weight: 600;
}
.valueHeader {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 15px;
}
.value {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: white;
}
.price {
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.label {
  font-size: 14px;
  color: #afbdc7;
}

.rightValue {
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: right;
}

.tooltip {
  padding: 0;
  margin-left: 10px;
  margin-top: -2px;
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.labelLink {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.cardTitleItem {
  width: 20%;
}
.w20 {
  width: 20%;
}
.w15 {
  width: 15%;
}
.w10 {
  width: 10%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}

.rightText {
  float: right;
  font-weight: 600;
  margin-top: 20px;
}

.speedo-segment {
  margin: 10px;
  padding: 10px;
}

.InfiniteCarouselArrowIcon {
  border-color: black;
}
.MuiSelect-icon {
  color: white;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .title {
    font-size: 30px;
    vertical-align: middle;
    font-weight: 800;
    line-height: 1.2;
    margin: 30px 0;
    text-align: center;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.betweenRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aroundRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.startRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.endRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}